/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #3EAF5A;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}
.App-header-Container{
  background-color: #3EAF5A;
  min-height: 15vh;
  position: relative;
}
.headertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(20px + 2vmin);
  color: white;
}
.learmore{
  position:absolute;
  bottom:0;
  right:0;
}

.welcome {
  margin-top: 20px;
  font-size: 20px;
  font-family: "Lucida Console", "Courier New", monospace;
}
.welcome1 {
  padding: 30px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-family: "Lucida Console", "Courier New", monospace;
}

.selection {
  margin: 40px;
}
.selection-item {
  margin-top: 8px;
  margin-bottom: 15px;
}
.selection-name {
  color: #3EAF5A;
  font-size: 24px;
  font-weight: 500;
}

.selection-dim {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.selection-submit {
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}

/* for range slider */
.dimension-range {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.dimension-col {
  height: 200px;
  margin-bottom: 80px;
}

.width-range {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.width-range1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 160px;
}

.width-track {
  height: 36px;
  display: flex;
  width: 100%;
}
.width-track1 {
  height: 36px;
  display: flex;
  width: 100%;
}

.width-track-background {
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
}

.width-thumb {
  height: 42px;
  width: 42px;
  border-radius: 4px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #AAA;
}
.width-thumb1 {
  height: 180px;
  width: 175px;
  border-radius: 1px;
  top: -60px;
  left: 60px;
  background-color: #FFF;
  background-image: url('./images/house.png');
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #AAA;
}

.width-thumb-label {
  position: absolute;
  top: -32px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  background-color: #159c16;
  width: 35px;
  text-align: center;
}
.width-thumb-label1 {
  position: absolute;
  top: 185px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  width: 95px;
  padding: 4px;
  border-radius: 3px;
  align-items: center;
  background-color: #159c16;
}

.height-range {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  margin-top: 30px;
}

.height-track {
  flex-grow: 1;
  width: 36px;
  display: flex;
  height: 200px;
}

.height-track-background {
  width: 5px;
  height: 100%;
  border-radius: 4px;
  align-self: center;
}

.height-thumb-label {
  position: absolute;
  right: -34px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  background-color: #159c16;
  width: 30px;
  text-align: center;
}


/* for tree species */
.tree-species-selector {
  display: flex;
  flex-direction: row;
}

.tree-species-selector input{
  margin:0;padding:0;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
}

.checkbox-1 {
  background-image: url('./images/norway_spruce.jpg');
}

.checkbox-2 {
  background-image: url('./images/colorado_spruce.jpg');
}

.checkbox-3 {
  background-image: url('./images/silver_fir.jpg');
}

.checkbox-4 {
  background-image: url('./images/eastern_red_cedar.jpg');
}

.checkbox-5 {
  background-image: url('./images/western_hemlock.jpg');
}

.checkbox-6 {
  background-image: url('./images/iowa_juniper.jpg');
}

.tree-species-selector input:checked +.tree-checkbox-image{
  border: 8px solid #3EAF5A;
  -webkit-filter: none;
     -moz-filter: none;
          filter: none;
}

.tree-checkbox-image {
  margin: 10px 60px 5px 0px;
  cursor:pointer;
  background-size:contain;
  background-repeat:no-repeat;
  display:inline-block;
  width:200px;height:292px;
}

.tree-species-name {
  margin: 0px 40px 10px 0px;
  width: 200px;
  text-align: center;
}

div label input {
  margin-right:100px;
}
body {
   font-family:sans-serif;
}

#ck-button{
   margin:-1px;
   margin-top: 10px;
   margin-bottom: 10px;
   background-color:#FFFFFF;
   border-radius:4px;
   width: 80px;
   border:2px solid #D0D0D0;
   overflow: hidden;
   float:left;
}
#ck-button label {
   float:middle;
   width:2.0em;
   margin-top: -10px;
   font-size: large;
   font-weight: 500;
   font-family: "Gill Sans", sans-serif;
}

#ck-button label span {
   text-align:center;
   margin-left: 25px;
}

#ck-button label input {
   position:relative;
   top:-20px;
}
.MuiStepLabel-labelContainer span {
  color: #3EAF5A;
  font-size: 24px;
  font-weight: 500;
}


.result-row {
  display: flex;
  flex-direction: row;
  width: 660px;
}

.result-row h4 {
  margin-right: 10px;
  margin-top: 80px;
}

.result-image {
  display: flex;
  flex-direction: column;
}

.result-image-row {
  display: flex;
  flex-direction: row;
}

.result-vertical-output {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 24px;
  align-self: center;
  margin-bottom: 24px;
  font-family: sans-serif;
}

.result-horizontal-output {
  width: 280px;
  font-size: 24px;
  text-align: center;
  font-family: sans-serif;
}

.results-table-title {
  margin-top: 20px;
  margin-bottom: 20px;
}
